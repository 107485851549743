import { Box, useBreakpointValue, Stack, Collapse, useDisclosure, useColorModeValue, Center, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { TabTitle } from "screens/common/components/TabTitle";
import { LandingTitle } from "screens/landing/components";
import {
  useCollections,
  useConfigMap,
  useDownloadInitialCollections,
  useIsInitialCollectionSyncCompleted,
  useIsMainAppLoading,
  useMenuConfig,
  useProjectConfigEntitlements,
  useTileProps,
  useUserPreference,
} from "hooks";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useWorkflows } from "hooks/useWorkflows";
import { HomeMetrics } from "./HomeMetrics";
import { ProjectNewInput } from "../project/ProjectNewInput";
import { HomeDefaultTile } from "../project/projectLandingTileLayouts/HomeDefaultTile";
import { HomeRightPanel } from "./HomeRightPanel";
import { HomeRightPanelMenu } from "./HomeRightPanelMenu";
import { HomeSkeleton } from "./HomeSkeleton";
import { ScrollingTickers } from "screens/common/components/ScrollingTickers";
import { OnboardingModal } from "screens/landing/components/popoverComponent/OnboardingModal";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";

export const HomeContent = () => {
  const { filteredProjectsIds, setFilteredProjectsIds } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { onConversationClose, onOnboardingModalOpen, isOnboardingModalOpen } = useConversationContext();
  const { setActionView } = useAddToCharliContext();
  const dashboardMyActivitesMax = useUserPreference("dashboard_my_activities_max") || 3;
  const fitTilesToContainer = useBreakpointValue([2, 3, dashboardMyActivitesMax as number]);
  const maxProjectTiles = useBreakpointValue([5, 5, 3, 6, 9]);
  const workflows = useWorkflows();
  const { isOpen: showMetrics, onToggle: toggleMetrics } = useDisclosure();
  const {
    isOpen: showActivities,
    onToggle: toggleActivities,
    onClose: closeActivities,
    onOpen: openActivities,
  } = useDisclosure({ defaultIsOpen: true });
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const bgSectionsColor = useColorModeValue("white", "gray.900");
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();
  const collections = useCollections();
  const configMap = useConfigMap();
  const menuConfig = useMenuConfig(configMap, "all", false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isInitialCollectionSyncCompleted = useIsInitialCollectionSyncCompleted();
  const isMainAppLoading = useIsMainAppLoading();
  const injectTestEntityPreference = useUserPreference("ui_inject_test_entity");
  const hasCompletedOnboarding = useUserPreference("ui_onboarding_completed") as boolean;
  const dispatch = useDispatch();
  const commonTileProps = useTileProps();

  const filterCollectionsByPortfolio = useCallback(
    (isFiltered: boolean) => {
      const portfolioCategory = menuConfig.filter((menuItem) => !!menuItem.config.portfolioProjectType);
      const portfolioCategoryTypes = portfolioCategory.map((menuItem) => menuItem.config.collectionType);

      if (!collections) {
        return [];
      }

      return collections.filter((collection) =>
        isFiltered
          ? portfolioCategoryTypes.includes(collection.collectionType)
          : !portfolioCategoryTypes.includes(collection.collectionType)
      );
    },
    [collections, menuConfig]
  );

  const nonPortfolioProjects = useMemo(() => {
    return filterCollectionsByPortfolio(false);
  }, [filterCollectionsByPortfolio]);

  useEffect(() => {
    if (hasCompletedOnboarding || injectTestEntityPreference || isMainAppLoading || !isInitialCollectionSyncCompleted) {
      return;
    }

    if (nonPortfolioProjects?.length > 0) {
      !hasCompletedOnboarding && dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
    } else {
      console.log("enters here");
      onOnboardingModalOpen();
    }
  }, [
    dispatch,
    hasCompletedOnboarding,
    injectTestEntityPreference,
    isInitialCollectionSyncCompleted,
    isMainAppLoading,
    nonPortfolioProjects?.length,
    onOnboardingModalOpen,
  ]);

  useEffect(() => {
    onConversationClose();
    setActionView(undefined);
    if (isMobile && showActivities) {
      closeActivities();
    } else openActivities();
    setHeaderText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useDownloadInitialCollections({ homeLimit: 15, portfolioLimit: 50 });

  useEffect(() => {
    const newFilteredProjects = filterCollectionsByPortfolio(false);
    setFilteredProjectsIds(newFilteredProjects.map((project) => project.id));
  }, [filterCollectionsByPortfolio, setFilteredProjectsIds]);

  if (isMainAppLoading || !isInitialCollectionSyncCompleted || fitTilesToContainer === undefined) {
    return <HomeSkeleton />;
  }
  if (!filteredProjectsIds || isOnboardingModalOpen) {
    return (
      <>
        <HomeSkeleton />
        <OnboardingModal />
      </>
    );
  }

  return (
    <>
      <Box height="100%" mb="2.5rem">
        <Center>
          <ScrollingTickers />
        </Center>
        <Center height={isMobile ? "17rem" : "28rem"} width="100%" px={isMobile ? "1rem" : "0"}>
          <ProjectNewInput collectionType={hasDueDiligence ? "due_diligence" : "all"} />
        </Center>
        {!isMobile && (
          <Collapse className="ch-home-metrics-section" animateOpacity in={showMetrics}>
            <HomeMetrics filteredWorkflows={workflows} />
          </Collapse>
        )}
        <Box bgColor={bgColor} height=".5rem" width="100%" />
        <Stack direction="row" justifyContent="space-between" bgColor={bgSectionsColor} spacing="0" height="100%">
          <Box height="100%" width="100%" className="ch-dashboard-recent-projects" bgColor={bgSectionsColor}>
            <Stack direction="row" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"} pb=".7rem">
              <LandingTitle text="Recent AI Due Diligence Projects" underline color="primary.default" />
            </Stack>
            <SimpleGrid spacing="1rem" columns={[1, 1, 1, 2, 3]} p="1rem">
              {filteredProjectsIds.length > 0 ? (
                filteredProjectsIds.slice(0, maxProjectTiles).map((id) => <HomeDefaultTile key={id} collectionId={id} />)
              ) : (
                <Box {...commonTileProps}>
                  <Center minH="11.8rem">
                    <Text color="primary.darkGray" fontSize={"sm"}>
                      No Projects created
                    </Text>
                  </Center>
                </Box>
              )}
            </SimpleGrid>
          </Box>
          {!isMobile && (
            <>
              <HomeRightPanelMenu
                toggleActivities={toggleActivities}
                showActivities={showActivities}
                toggleMetrics={toggleMetrics}
                showMetrics={showMetrics}
                setCurrentSlide={(slide) => {
                  setCurrentSlide(slide);
                  openActivities();
                }}
              />
              <Collapse className="ch-home-activities-section" animateOpacity in={showActivities} style={{ height: "100%" }}>
                <HomeRightPanel currentSlide={currentSlide} />
              </Collapse>
            </>
          )}
        </Stack>
      </Box>
      <OnboardingModal />
      <TabTitle title="Charli > Home" />
    </>
  );
};
