import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useContext } from "react";
import React from "react";
import { Stack, useBreakpointValue, useColorModeValue, Box, Tooltip, Center } from "@chakra-ui/react";
import { InteractionBar } from "screens/landing/components";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { useCollection, useCustomTheme, useItemSelector, useProjectParams, useSidebarNavigation, useUserPreference } from "hooks";
import { EditableInput } from "screens/content/common/TwoColumnTable/EditableInput";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ConversationContext } from "screens/thread/ConversationContext";
import { SidebarNavigationLogos } from "screens/landing/components/SidebarNavigationLogo";

interface Props {
  inputbarInitialText?: string;
}

export const HEADER_INTERACTION_BAR_SELECTOR = "charli-header-interaction-bar";

export const HeaderInteractionBar: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  inputbarInitialText,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { headerText, setHeaderText, setIsEditing, setSidebarWidth } = useContext(SettingsProviderContext);
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const { projectId, contentId } = useProjectParams();
  const dispatch = useDispatch();
  const collection = useCollection(projectId);
  const hoverColor = useColorModeValue("secondaryButton.hover", "secondaryButton.hoverDarkMode");
  const { onConversationClose, setRequestEntities, setConversationId } = useContext(ConversationContext);
  const { resetSelectedItems } = useItemSelector();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const fontColor = useColorModeValue("gray.700", "gray.400");
  const { currentSidebarType: reduxNavigationType } = useSidebarNavigation();
  const saveNavigationType = useUserPreference("ui_save_navigation_type");
  const userNavigationType = useUserPreference("ui_navigation_type");
  const currentSidebarType = saveNavigationType ? userNavigationType : reduxNavigationType;
  const { isEmbeddedApp } = useCustomTheme();

  const sendEditMessage = ({ entity, value }: { entity: string; value: string }) => {
    if (!collection || !projectId) {
      return;
    }
    const entities: {
      entity: string;
      value: string;
    }[] = [];
    entities.push({ entity: "collection_id", value: projectId });
    entities.push({ entity: "metadata_id", value: collection.metadata.id });
    entities.push({ entity: entity, value: value });

    setTimeout(() => {
      setIsEditing(false);
    }, 300);

    dispatch(
      sendMessage({
        conversationId: projectId,
        intent: "/edit_collection",
        entities: entities,
      })
    );
  };

  const getPath = useMemo(() => {
    if (pathname.includes("/json")) {
      return -1;
    }

    const truncatePath = pathname.includes("/library/resource") ? 2 : 1;
    const path = pathname.includes("/search/") ? "/" : pathname.split("/").slice(0, -truncatePath).join("/");
    return path;
  }, [pathname]);

  useEffect(() => {
    currentSidebarType === "hidden" && setSidebarWidth(0);
  }, [currentSidebarType, setSidebarWidth]);

  return (
    <Stack direction="row" spacing="0" height="2.6rem">
      {!isEmbeddedApp && <SidebarNavigationLogos />}
      <Stack
        spacing="0"
        height="100%"
        className={`${HEADER_INTERACTION_BAR_SELECTOR}`}
        width="100%"
        direction="row"
        justifyContent={headerText ? "space-between" : "flex-end"}
        border="none"
        backgroundColor={bgColor}>
        {!isMobile && headerText && (
          <Stack direction="row" width="100%" justifyContent="flex-start" pl="1rem">
            {(projectId || contentId) && !pathname.includes("/panel") && (
              <Box>
                <Tooltip label="Go back">
                  <ChevronLeftIcon
                    color={fontColor}
                    cursor="pointer"
                    zIndex="3"
                    height="2.5rem"
                    width="1.3rem"
                    backgroundColor={bgColor}
                    marginInlineStart="0px!important"
                    _hover={{ color: hoverColor }}
                    onClick={() => {
                      setRequestEntities([]);
                      setConversationId("");
                      onConversationClose();
                      setIsEditing(false);
                      resetSelectedItems();

                      if (getPath === -1) {
                        navigate(-1);
                      } else {
                        navigate(getPath);
                      }
                    }}
                  />
                </Tooltip>
              </Box>
            )}
            <Center width="100%" height={"100%"} alignContent="center">
              <EditableInput
                size="md"
                fontWeight="normal"
                maxHeight="2.6rem"
                isTextTruncated
                value={headerText}
                onEdit={(entity, value) => {
                  setHeaderText(value);
                  sendEditMessage({ entity, value });
                }}
                onCancel={() => setIsEditing(false)}
                isEditEnabled={projectId ? projectId.length > 0 : false}
                entity={"name"}
                backgroundColor={bgColor}
                fontColor={fontColor}
              />
            </Center>
          </Stack>
        )}
        <InteractionBar initialText={inputbarInitialText} />
      </Stack>
    </Stack>
  );
};
