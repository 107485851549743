import React, { startTransition, useCallback, useDeferredValue, useEffect, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import type { DropResult, DraggableStyle } from "@hello-pangea/dnd";
import { useFieldArray, Controller, useWatch, useFormContext } from "react-hook-form";
import {
  Stack,
  Text,
  FormControl,
  FormLabel,
  Box,
  Button,
  useColorModeValue,
  Checkbox,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Flex,
  Input,
} from "@chakra-ui/react";
import { RequirementsFieldArray } from "./RequirementsFieldArray";
import { useButtonProps } from "hooks";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { IncludedEntitiesFieldArray } from "./IncludedEntitiesFieldArray";
import { EntitiesToRenameFieldArray } from "./EntitiesToRenameFieldArray";
import { EntitiesToInjectFieldArray } from "./EntitiesToInjectFieldArray";
import { CheckpointUserIntentField } from "./CheckpointUserIntentField";
import { CheckpointIntentContextProvider } from "../context/CheckpointIntentContext";
import { EntityToSplit } from "./EntityToSplit";
import { CheckpointType } from "./CheckpointType";

const grid = 8;
const getItemStyle = (isDragging: boolean, draggableStyle?: DraggableStyle): React.CSSProperties => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  backgroundColor: isDragging ? "#F3F2F8" : "white",
  boxShadow: isDragging ? "0 0 10px rgba(0,0,0,0.5)" : "none",

  ...draggableStyle,
});

export const CheckpointsFieldArray = () => {
  const { control } = useFormContext<FormValues>();
  const [search, setSearch] = React.useState<string>("");
  const [checkpointsSearchList, setCheckpointsSearchList] = React.useState<
    { type: string; intent?: string; fields?: { field: string; value: string }[]; id: string }[]
  >([]);
  const deferredSearch = useDeferredValue(search);
  const bgColor = useColorModeValue("white!important", "gray.700!important");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const { fields, prepend, remove, move, insert } = useFieldArray({
    control,
    name: "config.checkpoints",
    keyName: "checkpointId",
  });

  const checkpoints = useWatch({
    control,
    name: "config.checkpoints",
  });

  const controlledCheckpoints = useMemo(
    () =>
      fields.map((field, index) => {
        return {
          ...field,
          ...checkpoints[index],
        };
      }),
    [checkpoints, fields]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      if (source.index === destination.index && source.droppableId === destination.droppableId) {
        return;
      }

      move(source.index, destination.index);
    },
    [move]
  );

  const renderCheckpoint = useCallback(
    (index: number) => {
      const checkpoint = controlledCheckpoints[index];

      if (checkpoint?.type === "execute_intent") {
        return (
          <>
            <Stack mb="1rem" direction="row" spacing={2} alignItems="flex-end">
              <CheckpointUserIntentField index={index} />
            </Stack>
            <Stack direction="row">
              <Box flex={"1"} padding={"1rem"} mt="1rem" mb="1rem" borderWidth="1px" borderRadius="lg">
                <EntitiesToInjectFieldArray checkpointIndex={index} />
              </Box>
            </Stack>
            <Stack direction="row">
              <Controller
                render={({ field }) => (
                  <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                    Confirm before running
                  </Checkbox>
                )}
                name={`config.checkpoints.${index}.needsConfirmation`}
                control={control}
              />
            </Stack>

            <Stack direction="row">
              <Controller
                render={({ field }) => (
                  <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                    Run in separate child workflow
                  </Checkbox>
                )}
                name={`config.checkpoints.${index}.runInNewChildWorkflow`}
                control={control}
              />
            </Stack>
            {checkpoint.runInNewChildWorkflow && (
              <>
                <Box mt={"1rem"} mb="1rem" borderWidth="1px" borderRadius="lg">
                  <Tabs isFitted>
                    <TabList>
                      <Tab>
                        <Flex>
                          <Text>Entities to Restrict to Child Workflow</Text>
                          {checkpoint.onlyIncludeTheseEntities?.length ? (
                            <Badge ml={"0.5rem"}>{checkpoint.onlyIncludeTheseEntities.length}</Badge>
                          ) : undefined}
                        </Flex>
                      </Tab>
                      <Tab>
                        <Flex>
                          <Text>Entities to Rename</Text>
                          {checkpoint.entitiesToRename?.length ? (
                            <Badge ml={"0.5rem"}>{checkpoint.entitiesToRename.length}</Badge>
                          ) : undefined}
                        </Flex>
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <IncludedEntitiesFieldArray checkpointIndex={index} />
                      </TabPanel>
                      <TabPanel>
                        <EntitiesToRenameFieldArray checkpointIndex={index} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
                <EntityToSplit checkpointIndex={index} />
                <Stack direction="row">
                  <Controller
                    render={({ field }) => (
                      <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                        Ignore child workflow if it fails (Don't fail entire workflow)
                      </Checkbox>
                    )}
                    name={`config.checkpoints.${index}.childWorkflowSuccessIsOptional`}
                    control={control}
                  />
                </Stack>
              </>
            )}
            <RequirementsFieldArray checkpointIndex={index} />
          </>
        );
      } else {
        return (
          <>
            <FormControl>
              <FormLabel fontSize="sm">Entities to Inject</FormLabel>
              <EntitiesToInjectFieldArray checkpointIndex={index} />
            </FormControl>
            <RequirementsFieldArray checkpointIndex={index} />
          </>
        );
      }
    },
    [controlledCheckpoints, control]
  );

  useEffect(() => {
    startTransition(() => {
      if (!deferredSearch) {
        setCheckpointsSearchList([]);
        return;
      }

      setCheckpointsSearchList(
        controlledCheckpoints.reduce(
          (ids: { type: string; intent?: string; fields?: { field: string; value: string }[]; id: string }[], checkpoint, index) => {
            const maybeIntent = checkpoint.intent || "N/A";
            if (checkpoint?.intent?.toLowerCase().includes(deferredSearch.toLowerCase())) {
              ids.push({ type: checkpoint.type, intent: maybeIntent, id: `config.checkpoints.${index}` });
            }

            checkpoint.entitiesToInject?.forEach(({ entity, value }, i) => {
              if (`${entity}-${value}`.toLowerCase().includes(deferredSearch.toLowerCase())) {
                ids.push({
                  type: checkpoint.type,
                  fields: [
                    { field: "Entity to inject", value: entity },
                    { field: "Entity to inject value", value },
                  ],
                  id: `config.checkpoints.${index}`,
                });
              }
            });

            checkpoint.onlyIncludeTheseEntities?.forEach(({ value }, i) => {
              if (value.toLowerCase().includes(deferredSearch.toLowerCase())) {
                ids.push({
                  type: checkpoint.type,
                  fields: [{ field: "Entity to restrict", value }],
                  id: `config.checkpoints.${index}`,
                });
              }
            });

            checkpoint.requirements?.forEach(({ entity, value }) => {
              if (entity.toLowerCase().includes(deferredSearch.toLowerCase())) {
                ids.push({
                  type: checkpoint.type,
                  fields: [
                    { field: "Requirement entity", value: entity },
                    { field: "Requirement entity value", value },
                  ],
                  id: `config.checkpoints.${index}`,
                });
              }
            });

            return ids;
          },
          []
        )
      );
    });
  }, [deferredSearch, controlledCheckpoints]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box backgroundColor={bgColor}>
        <Stack alignItems={"center"} direction="row" justifyContent="space-between" mb="1rem">
          <Input value={search} onChange={(evt) => setSearch(evt.target.value)} size={"sm"} placeholder="Search for checkpoint..." />
          <Button
            {...commonButtonProps}
            onClick={() =>
              prepend({
                intent: "",
                requirements: [],
                runInNewChildWorkflow: false,
                needsConfirmation: false,
                showEntityToSplit: false,
                entityToSplitName: "",
                entityToSplitRenameTo: "",
                entityToSplitShouldUnwrap: false,
                entitiesToInject: [],
                entitiesToRename: [],
                type: "execute_intent",
              })
            }>
            Add Checkpoint
          </Button>
        </Stack>
        {search && checkpointsSearchList.length > 0 && (
          <Box maxH={200} overflow="auto" mb={"1rem"} mt={"1rem"} boxShadow="md" p="6" rounded="md" bg="white">
            <Text fontSize={"md"}>Found {checkpointsSearchList.length} results</Text>
            {checkpointsSearchList.map(({ intent, type, fields, id }, index) => (
              <Box
                onClick={() => {
                  document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
                }}
                role={"button"}
                _hover={{ cursor: "pointer", backgroundColor: "gray.100" }}
                p={2}
                key={index}>
                <Text wordBreak={"break-all"} fontSize={"sm"}>
                  Checkpoint type:{" "}
                  <Text as={"b"} fontSize={"xs"}>
                    {type}
                  </Text>
                </Text>
                {intent && (
                  <Text wordBreak={"break-all"} fontSize={"sm"}>
                    Checkpoint intent:{" "}
                    <Text as={"b"} fontSize={"xs"}>
                      {intent}
                    </Text>
                  </Text>
                )}

                {fields &&
                  fields.length > 0 &&
                  fields.map(({ field, value }, i) => (
                    <Text wordBreak={"break-all"} fontSize={"sm"} key={i}>
                      {field}:{" "}
                      <Text as={"b"} fontSize={"xs"}>
                        {value}
                      </Text>
                    </Text>
                  ))}
              </Box>
            ))}
          </Box>
        )}
        {search && checkpointsSearchList.length === 0 && (
          <Text fontSize={"md"} mb={"1rem"}>
            No results found
          </Text>
        )}
        <CheckpointIntentContextProvider>
          <Droppable droppableId="checkpoints">
            {(droppableProvided) => (
              <Stack {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} spacing={4}>
                {fields.map((field, index) => (
                  <Draggable key={field.checkpointId} draggableId={field.checkpointId || String(index)} index={index}>
                    {(draggableProvided, snapshot) => (
                      <Box
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}
                        borderWidth="2px"
                        borderRadius="md"
                        padding="1rem"
                        left={"auto !important"}
                        id={`config.checkpoints.${index}`}>
                        {field.id && (
                          <Controller
                            name={`config.checkpoints.${index}.id`}
                            control={control}
                            render={({ field: controllerField }) => (
                              <FormControl>
                                <Input {...controllerField} type="hidden" />
                              </FormControl>
                            )}
                          />
                        )}

                        <CheckpointType control={control} checkpointIndex={index} onRemove={() => remove(index)} />

                        {renderCheckpoint(index)}

                        <Flex mt="1rem">
                          <Button
                            {...commonButtonProps}
                            onClick={() =>
                              insert(index + 1, {
                                intent: "",
                                requirements: [],
                                runInNewChildWorkflow: false,
                                needsConfirmation: false,
                                showEntityToSplit: false,
                                entityToSplitName: "",
                                entityToSplitRenameTo: "",
                                entityToSplitShouldUnwrap: false,
                                entitiesToInject: [],
                                entitiesToRename: [],
                                type: "execute_intent",
                              })
                            }>
                            Add new checkpoint after this one
                          </Button>
                          <Button {...commonButtonProps} ml={"0.5"} onClick={() => insert(index + 1, field)}>
                            Duplicate checkpoint
                          </Button>
                        </Flex>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </Stack>
            )}
          </Droppable>
        </CheckpointIntentContextProvider>
      </Box>
    </DragDropContext>
  );
};
