import {
  Box,
  Stack,
  useBreakpointValue,
  Icon,
  Tooltip,
  useDisclosure,
  Collapse,
  Button,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuDivider,
  Text,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext, useEffect } from "react";
import { CollectionsFilterContext, CollectionsFilterMenu, CollectionsTabHeaderFilter, ProjectLandingTiles } from "../collections";
import { useButtonProps, useCollection, useConfigMap, useGetViewConfig, useProjectParams, useTileProps } from "hooks";
import { getViewConfig } from "configs/configMap";
import { IoBarChartSharp } from "react-icons/io5";
import { LandingTitle } from "screens/landing/components";
import { ProjectMetrics } from "../collections/ProjectMetrics";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { NewProjectMenuItem } from "../library/NewProjectMenuItem";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { PausedWorkflowModalProvider } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { LuSettings2 } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";

interface Props {
  hideTitle?: boolean;
  collectionType: string;
  loadTilesCount?: number;
}

export const ProjectLanding: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  hideTitle,
  collectionType,
  loadTilesCount,
}) => {
  const { projectFilter } = useProjectParams();
  const { filteredCollections } = useContext(CollectionsFilterContext);
  const portfolioProject = useCollection(projectFilter);
  const configMap = useConfigMap();
  const portfolioCollectionType = getViewConfig("portfolioProjectType", portfolioProject?.collectionType || "", configMap);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const projectTitle = useGetViewConfig("title", portfolioCollectionType || projectFilter, configMap);
  const { isOpen: showMetrics, onToggle: toggleMetrics } = useDisclosure();
  const commonButtonProps = useButtonProps("sm", "primary");
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onClose: onFilterClose } = useDisclosure();
  const hasNewProjectForm = useGetViewConfig("newProjectForm", portfolioCollectionType || projectFilter, configMap);
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  const { onPortfolioSettingsOpen } = useAddToCharliContext();
  const commonTileProps = useTileProps();

  useEffect(() => {
    onFilterClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioProject]);

  return (
    <Box height="100%">
      <Stack spacing="0" justifyContent="space-between">
        <Collapse animateOpacity in={showMetrics}>
          <ProjectMetrics />
        </Collapse>
        {!isMobile && (
          <>
            <Stack px={isMobile ? ".5rem" : "1rem"} mt="0!important">
              <LandingTitle
                underline
                text={portfolioProject ? `${portfolioProject.name}` : `${projectTitle} Projects`}
                color={"primary.default"}>
                <Stack direction="row" spacing="0.5rem">
                  {portfolioProject && (
                    <Tooltip label="Rerun All Projects" aria-label="Rerun Projects">
                      <Box cursor="not-allowed">
                        <IconButton
                          isDisabled
                          className="ch-rerun-all-projects-button"
                          aria-label="Rerun Projects"
                          {...commonButtonProps}
                          icon={<Icon as={HiOutlineRefresh} boxSize="1.2rem" />}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {portfolioProject && (
                    <Tooltip label={`${showMetrics ? "Hide" : "Open"} Portfolio Settings`} aria-label="Show/Hide Settings">
                      <Box cursor="pointer" onClick={() => onPortfolioSettingsOpen()}>
                        <IconButton
                          className="ch-open-portfolio-settings-button"
                          aria-label="Show/Hide Settings"
                          {...commonButtonProps}
                          icon={<Icon as={LuSettings2} boxSize="1.2rem" />}
                          onClick={() => {
                            onPortfolioSettingsOpen();
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  <Tooltip label={`${showMetrics ? "Hide" : "Show"} Metrics`} aria-label="Show/Hide Metrics">
                    <Box cursor="pointer" onClick={() => toggleMetrics()}>
                      <IconButton
                        className="ch-project-landing-show-metrics"
                        aria-label="Show/Hide Metrics"
                        {...commonButtonProps}
                        icon={<Icon fontWeight={"bold"} as={IoBarChartSharp} />}
                        onClick={() => {
                          toggleMetrics();
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <Box>
                    <Menu isLazy>
                      <MenuButton
                        {...commonButtonProps}
                        className="ch-project-landing-actions-menu"
                        as={Button}
                        aria-label="Actions"
                        rightIcon={<TriangleDownIcon />}
                        onClick={(event) => event.stopPropagation()}>
                        Actions
                      </MenuButton>
                      <MenuList minWidth={"unset"} zIndex={10}>
                        {hasNewProjectForm && collectionType !== "due_diligence" && (
                          <>
                            <NewProjectMenuItem collectionType={collectionType} buttonLabel={`New ${projectTitle} Project`} />
                          </>
                        )}
                        {portfolioProject && (
                          <OptionsMenuItem
                            menuLabel="Portfolio Settings"
                            className="ch-open-project-settings"
                            onClick={() => {
                              onPortfolioSettingsOpen();
                            }}
                          />
                        )}
                        <OptionsMenuItem isDisabled menuLabel={"Rerun All Projects"} className="ch-rerun-all-projects" />
                        <OptionsMenuItem
                          menuLabel={`${showMetrics ? "Hide" : "Show"} Metrics`}
                          className="ch-open-project-metrics"
                          onClick={() => {
                            toggleMetrics();
                          }}
                        />
                        {portfolioProject && (
                          <>
                            <MenuDivider />
                            <ProjectDeleteButton
                              label="Delete Portfolio"
                              className="ch-delete-portfolio-landing"
                              onOpenConfirmation={onDeleteProjectModalOpen}
                              projectId={portfolioProject.id}
                              isMenuItem
                              navigatePath="/home"
                            />
                          </>
                        )}
                      </MenuList>
                    </Menu>
                  </Box>
                </Stack>
              </LandingTitle>
              <Stack justifyContent="flex-end" width="100%" direction="row">
                <Box width={isMobile ? "100%" : "50%"} maxWidth="30rem">
                  <CollectionsFilterMenu onFilterToggle={onFilterToggle} isOpen={isFilterOpen} hideStateFilter hideTagFilter />
                </Box>
              </Stack>
            </Stack>
          </>
        )}
        <Stack spacing="1rem" px={isMobile ? ".5rem" : "1rem"}>
          <PausedWorkflowModalProvider>
            {!hideTitle && <CollectionsTabHeaderFilter />}
            {filteredCollections.length > 0 ? (
              <Box mb={"5rem!important"}>
                <ProjectLandingTiles loadTilesCount={loadTilesCount} />
              </Box>
            ) : (
              <Box {...commonTileProps} height="5.3rem">
                <Text color="primary.darkGray" fontSize={"sm"} lineHeight="4.2rem">
                  No Projects created
                </Text>
              </Box>
            )}
          </PausedWorkflowModalProvider>
        </Stack>
      </Stack>
    </Box>
  );
};
