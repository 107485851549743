import React, { useContext } from "react";
import { SidebarButton } from "./SidebarButton";
import { getTypeIcon, getViewConfig, getTypeFromRoute } from "configs/configMap";
import { Box, Stack } from "@chakra-ui/react";
import type { ProjectConfigState } from "state/config/reducer";
import { SidebarCategory } from "./SidebarMenuCategory";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";

interface Props {
  reorderedMenuConfig: ProjectConfigState[];
  configMap: Record<string, ProjectConfigState>;
}

export const SidebarNavigationMainMenu = ({ reorderedMenuConfig, configMap }: Props) => {
  const { sidebarWidth } = useContext(SettingsProviderContext);

  return (
    <Stack spacing="1rem" key={"test"}>
      {reorderedMenuConfig.map((menuItem) => (
        <Box key={`category-${menuItem.id}`}>
          {menuItem.config.portfolioProjectType ? (
            <SidebarCategory
              text={getViewConfig("title", menuItem.id, configMap)}
              selectedProjectCategory={menuItem.config.collectionType}
              icon={getTypeIcon(getViewConfig("icon", menuItem.id, configMap))}
              className={`ch-sidebar-nav-${getTypeFromRoute(menuItem.config.collectionType)}`}
            />
          ) : (
            <SidebarButton
              key={`sidebar-nav-${menuItem.id}`}
              text={getViewConfig("title", menuItem.id, configMap)}
              icon={getTypeIcon(getViewConfig("icon", menuItem.id, configMap))}
              screen={getViewConfig("route", menuItem.id, configMap)}
              cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(menuItem.config.route)}`]}
              tooltip={sidebarWidth < 85 ? getViewConfig("title", menuItem.id, configMap) : menuItem?.config.menuTooltip}
            />
          )}
        </Box>
      ))}
    </Stack>
  );
};
